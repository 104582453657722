<template>
<div id="books_Intro">
  <cube-slide ref="slide" :data="items"  :auto-play="false">
    <cube-slide-item v-for="(item, index) in items" :key="index">
     <div class="box_cub_img" @click="showImagePreview(index)">
       <img :src="item.images" >
     </div>
    </cube-slide-item>
  </cube-slide>
</div>
</template>

<script>
export default {
    props:{
data: {

    }
  },
  data() {
    return {
      imgs: [],
      initialIndex: 0,
      items: [],
      infosData: {
        publish_date: '',
        word_count: "",
        isbn: '',
        author: '',
        name_lang: '',
        press_name_lang: {
          mn: ''
        },
        content_lang: {
          mn: '',
        }
      },
      // data1: { //获取书籍数据
      //   book_id: '',
      //   member_id: JSON.parse(localStorage.getItem('userInfoMemberId')).member_id,
      // },
      type: '2' //电子书
    }

  },
  methods: {
    showImagePreview(index) {
      this.initialIndex = index
      const params = {
        $props: {
          imgs: this.imgs, //存当前图片的数组，如果是渲染的每一项，通过形参传进来
          initialIndex: 'initialIndex', //把图片数组循环后，图片的索引
          loop: false
        },
        $events: {
          change: (i) => {
            // 必须更新 initialIndex
            this.initialIndex = i
          }
        }
      }
      this.$createImagePreview({
        ...params
      }).show()
    },

    /*****************************************************************
     *
     * 获取详情数据
     *
     *****************************************************************/
    infoData() {
      const _this = this
          let a = {
            images: _this.data.img
          }
          _this.imgs.push(_this.data.img)
          _this.items.push(a)
          _this.data.book_img.forEach(item => {
            let b = {
              images: item.url
            }
            _this.imgs.push(item.url)
            _this.items.push(b)
          });
      // _this.data1.book_id = _this.$route.query.id
      // _this.data1.member_id = JSON.parse(localStorage.getItem('userInfoMemberId')).member_id
      // _this.showToastMask(true)

      // _this.$http.get('api/web/v1/book/find/' + _this.data1.book_id + '?member_id=' + _this.data1.member_id)
      //   .then(val => {
      //     _this.showToastMask(false)

      //     console.log(val.data, '介绍')

      //     console.log(_this.imgs, '_this.items')

      //   }).catch(err => {
      //     _this.showToastMask(false)

      //   })
    },
  },
  created() {

    this.infoData()
  },
	activated(){
		let pdfOpen = sessionStorage.getItem("pdfOpen") ? sessionStorage.getItem("pdfOpen") : null
		if(pdfOpen!=='true'){
      this.infoData()
		}
	}
}
</script>

<style lang="less">
#books_Intro {
  height: calc(100vh - 291px);
  .cube-slide-item {

    .box_cub_img {
      width: 70%;
      margin: 0 auto;
      img {
        width: 100%;
        display: block;
        margin: 0 auto;
        height: 100%;
      }
    }
  }

  .cube-slide-dots {
    bottom: 0;
  }
}
</style>
